@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

@font-face {
    font-family: 'Alcubierre';
    src: url("./fonts/Alcubierre.otf")
}

html {
    scroll-behavior: smooth;
    height: auto;
}